<template>
  <div class="top-bar">
    <Navbar/>
  </div>
</template>
<script>

import { Navbar } from 'global-navbar';

export default {
  name: 'header-page',
  components: { Navbar },
  data() {
    return {
      height: 0,
      heightMonitor: null,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.monitorHeight();
      this.checkHeight();
      window.removeEventListener('resize', this.checkHeight);
      window.addEventListener('resize', this.checkHeight);
    });
  },
  beforeDestroy() {
    this.heightMonitor.disconnect();
  },
  methods: {
    monitorHeight() {
      this.heightMonitor = new MutationObserver(this.checkHeight);
      this.heightMonitor.observe(document.body, {
        attributes: true,
        childList: true,
        subtree: true,
        characterData: true
      });
    },
    checkHeight() {
      const clientHeight = document.getElementsByClassName('action-menu-item-container-wrapper show').length > 0
        ? document.getElementsByClassName('main-nav')[0].offsetHeight + document.getElementsByClassName('action-menu-item-container-wrapper show')[0].offsetHeight
        : document.getElementsByClassName('main-nav')[0].offsetHeight;
      if (clientHeight !== this.height) {
        this.height = clientHeight;
        console.log(`New Header Height : ${this.height}`);
        window.top.postMessage({ 'header-height': this.height }, '*');
      }
    }
  }
};

</script>
